import axios from "axios";
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const url = "https://photobook-beta.neulus.com/admin/password";

    const token = window.prompt("Enter the token");
    const number = window.prompt("Enter the number");
    const newPassword = window.prompt("Enter new password");

    axios
      .patch(
        url,
        { number: number, password: `${newPassword}` },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Admin-Token": token,
          },
        }
      )
      .then((res) => {
        alert(res.data.message);
      })
      .catch((err) => {
        console.log(err.response);
        alert(err.response.data.detail[0].msg);
      });
  }, []);

  return <div className="App"></div>;
}

export default App;
